import AuthenticImageMentoringFallback from "../assets/images/services/authentic-image-mentoring.jpg";
import FindYourStyleFallback from "../assets/images/services/find-your-style.jpg";
import TravelBagPlanningFallback from "../assets/images/services/travel-bag-planning.jpg";
import StylingFallback from "../assets/images/services/styling.jpg";
import ComercialStylingFallback from "../assets/images/services/comercial-styling.jpg";
import LookAssemblyFallback from "../assets/images/services/look-assembly.jpg";
import PersonalColorAnalysisFallback from "../assets/images/services/personal-color-analysis.jpg";
// import WorkshopProFallback from "../assets/images/services/workshop-pro.jpg";
import GiftVouchersFallback from "../assets/images/services/gift-vouchers.jpg";
import AuthenticImageMentoring from "../assets/images/services/authentic-image-mentoring.webp";
import FindYourStyle from "../assets/images/services/find-your-style.webp";
import TravelBagPlanning from "../assets/images/services/travel-bag-planning.webp";
import Styling from "../assets/images/services/styling.webp";
import ComercialStyling from "../assets/images/services/comercial-styling.webp";
import LookAssembly from "../assets/images/services/look-assembly.webp";
import PersonalColorAnalysis from "../assets/images/services/personal-color-analysis.webp";
// import WorkshopPro from "../assets/images/services/workshop-pro.webp";
import GiftVouchers from "../assets/images/services/gift-vouchers.webp";

export const SERVICES = [
  // {
  //   image: WorkshopPro,
  //   imageFallback: WorkshopProFallback,
  //   // title: "Mentoria Imagem de Topo",
  //   title: "Mentoria Premium (Brevemente)",
  //   url: "premium-image-mentoring",
  //   description:
  //     "Atinja o próximo patamar de sucesso, através de uma imagem de topo.",
  //   disabled: true,
  // },
  {
    image: AuthenticImageMentoring,
    imageFallback: AuthenticImageMentoringFallback,
    title: "Mentoria de Imagem Autêntica",
    url: "authentic-image-mentoring",
    description:
      "Pare de se sentir perdida e comece a sentir-se você mesma, através de uma jornada de autoconhecimento, numa mentoria exclusiva.",
  },
  {
    image: FindYourStyle,
    imageFallback: FindYourStyleFallback,
    title: "Consultoria de Estilo Pessoal",
    url: "find-your-style",
    description:
      "Atinja o seu principal objetivo e reencontre-se, através de uma consultoria personalizada.",
  },
  {
    image: LookAssembly,
    imageFallback: LookAssemblyFallback,
    title: "Montagem de Looks Sublimes",
    url: "look-assembly",
    description:
      "Deslumbre no seu dia-a-dia, sem perder o seu valioso tempo a organizar os looks do mês.",
  },
  {
    image: Styling,
    imageFallback: StylingFallback,
    title: "Styling de Eventos",
    url: "styling",
    description:
      "Demonstre confiança e arrase em qualquer evento, com um serviço especializado na orientação da escolha do visual mais apropriado.",
  },
  {
    image: ComercialStyling,
    imageFallback: ComercialStylingFallback,
    title: "Styling Comercial",
    url: "styling-comercial",
    description:
      "Alcance a campanha ideal, com um serviço especializado no destaque do seu produto, através de looks estrategicamente selecionados.",
  },
  {
    image: TravelBagPlanning,
    imageFallback: TravelBagPlanningFallback,
    title: "Planeamento de Looks de Viagem",
    url: "travel-bag-planning",
    description:
      "Viaje com mais conforto, estilo e sem medo de errar, através de uma mala estrategicamente planeada, exclusivamente para si.",
  },
  {
    image: PersonalColorAnalysis,
    imageFallback: PersonalColorAnalysisFallback,
    title: "Coloração Pessoal",
    url: "personal-color-analysis",
    description:
      "Aprenda que maquilhagem, cor de cabelo, acessórios e roupa a favorecem, através do Método Sazonal Expandido, sem ficar refém da sua cartela.",
  },
  {
    image: GiftVouchers,
    imageFallback: GiftVouchersFallback,
    title: "Ofereça um dos nossos Vales Presente!",
    url: "gift-vouchers",
    description: "Ofereça a prenda perfeita para quem mais ama: autoconfiança.",
  },
];
