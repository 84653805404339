import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import MailchimpSubscribe from "react-mailchimp-subscribe";
import Spinner from "../Spinner/Spinner";
import "./NewsletterSignup.css";

const MAILCHIMP_URL = process.env.REACT_APP_MAILCHIMP_URL;

const SignupForm = ({ subscribe, status, message, onSuccess }) => {
  const [email, setEmail] = useState();
  const [subscriptionStatus, setSubscriptionStatus] = useState();

  useEffect(() => {
    let timer;
    if (subscriptionStatus !== status) setSubscriptionStatus(status);
    if (status === "success") {
      timer = setTimeout(() => {
        onSuccess?.();
        document.getElementById("newsletter-signup-form-input").value = "";
        setEmail(null);
        setSubscriptionStatus(null);
      }, 5000);
    }

    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  return (
    <div className="newsletter-signup-inputs-container">
      <div className="newsletter-signup-inputs-wrapper">
        <input
          id="newsletter-signup-form-input"
          className="newsletter-signup-form-input"
          type="email"
          placeholder="O seu email"
          onChange={(e) => {
            setSubscriptionStatus(null);
            setEmail(e?.target?.value);
          }}
        ></input>

        <div className="newsletter-signup-spinner-submit-button-wrapper">
          {subscriptionStatus === "sending" ? (
            <div className="newsletter-signup-spinner-wrapper">
              <Spinner small />
            </div>
          ) : (
            <button
              className="newsletter-signup-form-button"
              type="submit"
              onClick={() =>
                email?.indexOf("@") > -1 &&
                subscribe({
                  EMAIL: email,
                })
              }
            >
              SUBSCREVER
            </button>
          )}
        </div>
      </div>
      <div className="newsletter-signup-form-status-message-wrapper">
        {subscriptionStatus === "error" && (
          <p className="newsletter-signup-form-error-message">
            Algo correu mal. Por favor, tente novamente.
            {console.error(message)}
          </p>
        )}
        {subscriptionStatus === "success" && (
          <p className="newsletter-signup-form-success-message">
            Subscrito com sucesso!
          </p>
        )}
      </div>
    </div>
  );
};

const NewsletterSignup = ({ className = "footer", onSuccess = null }) => (
  <div className={`newsletter-signup-wrapper ${className}-wrapper`}>
    <h2 className={`newsletter-signup-title  ${className}-title`}>
      Subscreva a nossa Newsletter
    </h2>
    <p className="newsletter-signup-description">
      Conteúdo exclusivo, e-books gratuitos e muito mais, todos os meses
    </p>
    <div className="newsletter-signup__legal-disclaimer">
      Ao subscrever, declara que aceita os{" "}
      <Link className="footer__legal-links" to={`/legal/terms-conditions`}>
        Termos e Condições
      </Link>
      <span className="footer__legal-links"> e </span>
      <Link className="footer__legal-links" to={`/legal/privacy-policy`}>
        Política de Privacidade
      </Link>
      .
      <div className="newsletter-signup__legal-disclaimer-unsubscribe">
        Se desejar cancelar a subscrição, basta clicar "Unsubscribe" no fundo
        dos nossos emails.
      </div>
    </div>
    <MailchimpSubscribe
      url={MAILCHIMP_URL}
      render={(hooks) => <SignupForm onSuccess={onSuccess} {...hooks} />}
    />
  </div>
);

export default NewsletterSignup;
