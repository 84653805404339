import { useState, memo } from "react";
import { Link } from "react-router-dom";
import { SERVICES } from "../../constants/services";
import "./NavBar.css";

const submenuOptions = SERVICES.map(({ title, url }, index) => (
  <Link key={index} className="navbar__link" to={`/services/${url}`}>
    {title}
  </Link>
));

const NavBar = () => {
  const [showSubmenu, setShowSubmenu] = useState(true);

  return (
    <nav className="navbar">
      <Link className="navbar__link" to="/">
        Home
      </Link>
      <div
        className="navbar__services"
        onMouseEnter={() => setShowSubmenu(true)}
        onMouseLeave={() => setShowSubmenu(false)}
      >
        <Link className="navbar__link" to="/services">
          Serviços
        </Link>
        {showSubmenu && (
          <div className="submenu" onClick={() => setShowSubmenu(false)}>
            {submenuOptions}
          </div>
        )}
      </div>
      <Link className="navbar__link" to="/about">
        Sobre
      </Link>
      <Link className="navbar__link" to="/contacts">
        Contactos
      </Link>
    </nav>
  );
};

export default memo(NavBar);
